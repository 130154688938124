import React from "react"
import Plus from "../svg/arrow"
const ProductSizes = ({
  color,
  variants,
  onSelectedSize,
  colors,
  size,
  sizes,
  activeSize,
  setActiveSize,
}) => {
  const [active, toggle] = React.useState(false)

  const selected =
    colors.length > 0
      ? variants.filter(v => v.title.includes(color))
      : variants.filter(v => v.title.includes(size))

  function setMySize(index, size) {
    onSelectedSize(size)
    setActiveSize(index)
  }

  return (
    sizes.length > 0 && (
      <div className="relative z-20 bg-gray-100">
        <button
          onClick={() => toggle(!active)}
          className="flex items-center justify-between w-full leading-none cursor-pointer padding h-45px md:h-30px"
        >
          <span>
            Size
            {activeSize !== null && (
              <span className="px-2">
                {colors.length > 0
                  ? selected[activeSize]?.selectedOptions.find(
                      sel => sel.name === "Size"
                    ).value
                  : selected[0]?.selectedOptions.find(
                      sel => sel.name === "Size"
                    ).value}
              </span>
            )}
          </span>
          <figure
            className={`w-7.5px h-auto transition duration-500 transform  ${
              active ? "rotate-180" : ""
            }`}
          >
            <Plus></Plus>
          </figure>
        </button>

        <div
          className={`flex transition-all duration-700 border-t ${
            active ? "max-h-24 border-gray-200" : "max-h-0 border-transparent"
          }`}
        >
          <div
            className={`flex w-full transition-opacity bg-gray-100  -mb-45px md:-mb-30px ${
              active ? "opacity-100" : "opacity-0 delay-0 "
            }`}
          >
            {colors.length > 0
              ? selected.map((size, index) => {
                  return (
                    <button
                      className={`w-full fixed-btn text-center line-link  ${
                        activeSize === index ? "selected" : ""
                      }`}
                      onClick={() =>
                        setMySize(
                          index,
                          size.selectedOptions.find(sel => sel.name === "Size")
                            .value
                        )
                      }
                      key={size.id}
                    >
                      <span>
                        {
                          size.selectedOptions.find(sel => sel.name === "Size")
                            .value
                        }
                      </span>
                    </button>
                  )
                })
              : sizes &&
                sizes[0]?.values.map((value, index) => {
                  return (
                    <button
                      className={`w-full fixed-btn text-center line-link  ${
                        activeSize === index ? "selected" : ""
                      }`}
                      onClick={() => setMySize(index, value)}
                      key={index}
                    >
                      <span>{value}</span>
                    </button>
                  )
                })}
          </div>
        </div>
      </div>
    )
  )
}

export default ProductSizes
