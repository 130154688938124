import React from "react"
import { Link } from "gatsby"
import { breadcrumbs } from "./breadcrumbs.module.scss"
const Breadcrumbs = ({ title, slug }) => {
  return (
    <div className="hidden overflow-hidden lg:block">
      <nav className="flex leading-none capitalize bg-gray-50 padding ">
        <Link className={breadcrumbs} to="/store">
          Store
        </Link>
        <Link className={breadcrumbs} to={`/store/${slug}`}>
          {title}
        </Link>
        {/* <span className={breadcrumbs}>{title}</span> */}
      </nav>
    </div>
  )
}

export default Breadcrumbs
