import React from "react"
import { Navigation } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
// import RichText from "../text/RichText"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "../section/carousel.scss"
import sanityClient from "../../utils/client"
import imageUrlBuilder from "@sanity/image-url"

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}
const DetailsMobile = ({ details }) => {
  if (details) {
    const images = details.filter(item => item._type === "detailImage")

    return (
      <div className="block -mt-px md:hidden bg-gray-50">
        <div className="bg-white ">
          <Swiper modules={[Navigation]} slidesPerView={"auto"} navigation>
            {images &&
              images.map((image, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className={` swiper-slide-details ${
                      images.length === 1 ? "single" : ""
                    }`}
                  >
                    <div className="aspect-w-4 aspect-h-5">
                      <img
                        src={urlFor(image?.image?.asset?._ref).url()}
                        alt=""
                        className="object-cover w-full h-full"
                      />
                    </div>
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default DetailsMobile
