import React from "react"
import RichText from "../text/RichText"
// import UtilImage from "../util/UtilImage"
import sanityClient from "../../utils/client"
import imageUrlBuilder from "@sanity/image-url"

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}
const Details = ({ details }) => {
  return (
    <div className="hidden grid-cols-2 md:grid lg:grid-cols-4 ">
      {details &&
        details.map(detail => {
          return (
            <div
              key={detail._key}
              className="flex flex-col aspect-w-4 aspect-h-5 bg-gray-50"
            >
              {detail._type === "productContent" ? (
                <div className="flex flex-col self-stretch wrap py-15px md:py-20px content">
                  <header className="flex justify-between">
                    <span>{detail.title}</span>
                    {detail.subtitle && <span>{detail.subtitle}</span>}
                  </header>

                  {detail.content &&
                    detail.content.map(child => {
                      child.markDefs = child.markDefs ? child.markDefs : []
                      return (
                        <div className="mt-20px" key={child._key}>
                          <RichText key={child._key} blocks={child} />
                        </div>
                      )
                    })}
                  {detail.footer &&
                    detail.footer.map(child => {
                      child.markDefs = child.markDefs ? child.markDefs : []
                      return (
                        <footer className="mt-auto" key={child._key}>
                          <RichText
                            className="mt-15px"
                            key={child._key}
                            blocks={child}
                          />
                        </footer>
                      )
                    })}
                </div>
              ) : (
                <img
                  src={urlFor(detail?.image?.asset?._ref).url()}
                  alt=""
                  className="absolute top-0 left-0 w-full h-full picture-cover"
                />
              )}
            </div>
          )
        })}
    </div>
  )
}

export default Details
