import React from "react"
import Arrow from "../svg/arrow"

const Information = ({
  information,
  index,
  activeInfoIndex,
  setInfoIndex,
  setInfoContent,
}) => {
  const toggleContent = content => {
    if (activeInfoIndex === index) {
      setInfoIndex(null)
      setTimeout(() => setInfoContent(null), 200)
    } else {
      setInfoIndex(index)
      setTimeout(() => setInfoContent(content), 200)
    }
  }

  return (
    <div className={`border-b border-gray-200 toggle-group -mb-px`}>
      <div
        role="button"
        onClick={() => toggleContent(information.content)}
        onKeyDown={() => toggleContent(information.content)}
        tabIndex={0}
        className="flex items-center justify-between pr-3 cursor-pointer h-45px md:h-30px group"
      >
        <span>{information.title}</span>
        <figure
          className={` w-7.5px h-auto transition duration-500 group-hover:opacity-100 transform  ${activeInfoIndex === index ? "rotate-180" : "opacity-25"
            }`}
        >
          <Arrow></Arrow>
        </figure>
      </div>
    </div>
  )
}

export default Information
