import * as React from "react"
import { StoreContext } from "../../context/store-context"
import { HeaderContext } from "../../context/header-context"

export function AddToCart({
  variantId,
  quantity,
  available,
  className,
  size,
  sizes,
}) {
  const { addVariantToCart, loading, checkout } = React.useContext(StoreContext)
  const { toggleCart, toggleCurrency } = React.useContext(HeaderContext)
  const [error, showError] = React.useState(false)
  const [checkVariant, setCheckVariant] = React.useState(undefined)

  React.useEffect(() => {
    const check = checkout.lineItems.find(l => l.variant.id === variantId)
    setCheckVariant(check)
  }, [variantId, checkout.lineItems])

  async function addToCart(e) {
    e.preventDefault()
    if (sizes.length > 0) {
      if (size !== "") {
        await addVariantToCart(variantId, quantity)
        await toggleCart(true)
        await toggleCurrency(false)
        showError(false)
      } else {
        showError(true)
      }
    } else {
      await addVariantToCart(variantId, quantity)
      await toggleCart(true)
      await toggleCurrency(false)
      showError(false)
    }
  }

  return (
    <div className="relative">
      {error && size === "" && (
        <div className="absolute bottom-0 w-full mb-40px md:mb-30px pb-10px text-red">
          {" "}
          Please Select Size
        </div>
      )}
      <button
        type="submit"
        className={className}
        onClick={addToCart}
        disabled={!available || loading}
      >
        {size === ""
          ? "Add to bag"
          : available
          ? checkVariant
            ? `${checkVariant.quantity} in bag`
            : "Add to Bag"
          : "Out of Stock"}
      </button>
    </div>
  )
}
