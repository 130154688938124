import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { StoreContext } from "../context/store-context"
import { AddToCart } from "../components/addToCart/add-to-cart"
import Breadcrumbs from "../components/product/Breadcrumbs"
import ProductPrice from "../components/product/ProductPrice"
import ProductSizes from "../components/product/ProductSizes"
import RichText from "../components/text/RichText"
import ProductGrid from "../components/product/ProductGrid"
import Information from "../components/product/Information"
import Details from "../components/product/Details"
import DetailsMobile from "../components/product/DetailsMobile"
import TextTitle from "../components/section/TextTitle"
import InfoContent from "../components/product/InfoContent"
import Seo from "../components/seo/seo"
import { currencyHook } from "../utils/currencyHook"
import { FetchHook } from "../utils/fetchHook"
import sanityClient from "../utils/client"
import ProductCarousel from "../components/product/ProductCarousel"
import { format, hMdScreen } from "../pages/store/product.module.scss"
export default function Product({ data: { product, sanePro, settings } }) {
  const {
    variants,
    variants: [initialVariant],
    title,
    description,
    images: [firstImage],
  } = product
  const { client, currencyCode } = useContext(StoreContext)
  const [index, setIndex] = useState(0)

  const allImage = useRef(
    sanePro.images.length > 0 ? sanePro.images : product.images
  )

  // const changeImage = (e, i) => {
  //   e.preventDefault()
  //   setIndex(i)
  // }
  const { fetchedProduct } = FetchHook(sanePro.store.id)
  const colors = sanePro.store.options.filter(opt => opt.name === "Color")
  const sizes = sanePro.store.options.filter(opt => opt.name === "Size")
  const [color, setColor] = useState(colors[0]?.values[0])
  const [size, setSize] = useState("")
  const [activeSize, setActiveSize] = useState(null)
  const variantSelect =
    colors.length > 0
      ? sizes.length > 0
        ? variants.find(item => item.title === `${size} / ${color}`)
        : variants.find(item => item.title === `${color}`)
      : variants.find(item => item.title === `${size}`)

  const productVariant = variantSelect || initialVariant

  const [activeColor, setActiveColor] = useState(0)

  const [available, setAvailable] = useState(productVariant.availableForSale)

  const [activeInfoIndex, setInfoIndex] = useState(null)
  const [infoContent, setInfoContent] = useState(null)

  var price
  var compareAt

  if (fetchedProduct) {
    const fallbackPrices = [
      {
        price: {
          currencyCode: "GBP",
          amount: productVariant.price,
        },
      },
    ]
    const presentmentPrices = fetchedProduct.variants[0].presentmentPrices
    const { currencyConversion } = currencyHook(
      presentmentPrices ? presentmentPrices : fallbackPrices
    )
    price = currencyConversion(productVariant.price, currencyCode)

    compareAt = currencyConversion(productVariant.compareAtPrice, currencyCode)
  }
  // const { currencyConversion } = currencyHook(presentmentPrices)

  // useEffect(() => {
  //   currencyConversion(productVariant.price, currencyCode)
  // }, [currencyCode, productVariant, currencyConversion])
  // console.log(allImage.current)

  function selectedColor(index, color, images) {
    setActiveColor(index)

    setColor(color)
    setSize("")
    setActiveSize("")
    setIndex(0)

    const shopVariant = product.variants.find(variant =>
      variant.title.includes(color)
    )
    // console.log(shopVariant)

    allImage.current = images ? images : [shopVariant.image]
    console.log(allImage.current)
    if (variantSelect) {
      setAvailable(variantSelect.availableForSale)
    }
  }

  function selectedSize(size) {
    setSize(size)
    if (variantSelect) {
      setAvailable(variantSelect.availableForSale)
    }
  }

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )
  useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const hasAssetProperty = allImage.current[index]?.hasOwnProperty("asset")

  const [clientProduct, setClientProduct] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="product" && _id=="${sanePro._id}" ][0]{_id,details,information,description} `
      )
      .then(data => setClientProduct(data))
      .catch(console.error)
  }, [sanePro._id])

  return (
    <>
      <Breadcrumbs
        title={sanePro.store.title}
        slug={sanePro.store.slug.current}
      />
      <div className="relative flex flex-col flex-1 lg:flex-row ">
        <div className="flex-1 lg:w-1/2">
          <figure
            className={`md:block hidden relative w-full`}
            style={{ backgroundColor: `${settings.nodes[0].cardColour.hex}` }}
          >
            {allImage.current && (
              <GatsbyImage
                image={
                  hasAssetProperty
                    ? allImage.current[0]?.asset?.gatsbyImageData
                    : allImage.current[0]?.gatsbyImageData
                }
                alt="our product"
                objectFit="contain"
                className={` object-contain object-center w-full h-full`}
              ></GatsbyImage>
            )}
          </figure>
          <div className="block h-full md:hidden">
            <div className="relative ">
              <ProductCarousel images={allImage.current} />
            </div>
          </div>
          {allImage.current.length > 1 && (
            <div className="justify-center hidden grid-cols-2 md:grid">
              {allImage.current &&
                allImage.current.map((img, i) => {
                  if (i === 0) {
                    return <div key={i} className="hidden"></div>
                  }
                  return (
                    <div className="aspect-w-4 aspect-h-5" key={i}>
                      {/* <button
                        onClick={e => changeImage(e, i)}
                        className={`focus:outline-none w-full    flex items-center justify-center`}
                        style={{
                          backgroundColor: `${settings.nodes[0].cardColour.hex}`,
                        }}
                      > */}
                      <div>
                        <GatsbyImage
                          image={
                            hasAssetProperty
                              ? img?.asset?.gatsbyImageData
                              : img?.gatsbyImageData
                          }
                          alt="our product"
                          className="w-full h-full "
                          objectFit="cover"
                        ></GatsbyImage>
                      </div>
                      {/* </button> */}
                    </div>
                  )
                })}
            </div>
          )}
        </div>
        <div
          className={`lg:sticky top-0 flex flex-col items-center justify-center wrap  pt-20px lg:pb-4 lg:w-1/2 ${hMdScreen} lg:pt-9 lg:-mt-9 lg:min-h-window `}
        >
          <div className="w-full lg:w-1/2">
            <header className="mb-30px">
              <div className="flex justify-between text-md lg:text-base">
                <h1>{product.title}</h1>
                <ProductPrice
                  price={price}
                  compareAt={compareAt}
                  sale={Number(productVariant.compareAtPrice)}
                ></ProductPrice>
              </div>
              {sanePro.subtitle && (
                <span className="text-gray-300">{sanePro.subtitle}</span>
              )}
            </header>

            <div className={format}>
              {clientProduct && clientProduct.description
                ? clientProduct.description.map(child => {
                    child.markDefs = child.markDefs ? child.markDefs : []
                    return <RichText key={child._key} blocks={child} />
                  })
                : product.description && <p>{product.description}</p>}

              <span className="block mt-30px py-2.5">
                {sanePro.madeIn ? sanePro.madeIn : settings.nodes[0].madeIn}
              </span>
            </div>
            <div className="border-t">
              <div className="flex items-center px-3 space-x-6 h-70px md:h-auto lg:p-6 lg:px-6">
                {colors &&
                  colors[0]?.values.map((color, index) => {
                    const hex = sanePro.hexcodeImage.find(
                      h => h.color === color
                    )
                    const hexcode =
                      hex?.hexcode !== undefined ? hex?.hexcode : "#ffffff"
                    return (
                      <button
                        key={index}
                        onClick={() => selectedColor(index, color, hex?.images)}
                        className={`${
                          activeColor === index
                            ? "border-black"
                            : "border-transparent"
                        } flex items-center justify-center transition-colors duration-300 border rounded-full  p-2px`}
                      >
                        <span
                          style={{ backgroundColor: `${hexcode}` }}
                          className={`${
                            (hexcode === "#ffffff" || hexcode === "#FFFFFF") &&
                            activeColor !== index &&
                            "border"
                          } transitino-all duration-300 block rounded-full w-16px h-16px md:w-14px md:h-14px`}
                        ></span>
                      </button>
                    )
                  })}
              </div>
              <ProductSizes
                size={size}
                color={colors && colors[0]?.values[activeColor]}
                colors={colors}
                sizes={sizes}
                variants={variants}
                onSelectedSize={selectedSize}
                available={available}
                activeSize={activeSize}
                setActiveSize={setActiveSize}
              ></ProductSizes>

              <AddToCart
                variantId={productVariant.storefrontId}
                quantity={1}
                available={available}
                size={size}
                sizes={sizes}
                className="w-full leading-none text-white bg-black fixed-btn mt-45px md:mt-30px"
              />
              <div className={`grid grid-cols-1 md:grid-cols-2 md:gap-x-3 `}>
                {clientProduct?.information &&
                  clientProduct.information.map((information, index) => {
                    return (
                      <Information
                        key={information._key}
                        index={index}
                        information={information}
                        activeInfoIndex={activeInfoIndex}
                        setInfoIndex={setInfoIndex}
                        setInfoContent={setInfoContent}
                      ></Information>
                    )
                  })}
                {activeInfoIndex !== null && (
                  <InfoContent
                    activeInfoIndex={activeInfoIndex}
                    infoContent={infoContent}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {clientProduct?.details && (
        <div>
          <TextTitle
            title="Details and Features"
            className={
              "border-t border-b lg:border-b-0 border-gray-200 lg:border-t-0"
            }
          ></TextTitle>
          <Details details={clientProduct?.details} />
          <DetailsMobile details={clientProduct?.details} />
        </div>
      )}

      {sanePro.recommended.length > 0 && (
        <div>
          <TextTitle title="Recommended"></TextTitle>
          <ProductGrid
            products={sanePro.recommended}
            settings={settings.nodes[0].cardColour}
          ></ProductGrid>
        </div>
      )}

      {firstImage ? (
        <Seo title={title} description={description} image={firstImage} />
      ) : undefined}
    </>
  )
}

export const query = graphql`
  query($id: String!, $handle: String!) {
    sanePro: sanityProduct(store: { slug: { current: { eq: $handle } } }) {
      _id
      madeIn
      subtitle
      hexcodeImage {
        color
        hexcode
        images {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            mobile: gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
            url
          }
        }
      }
      description {
        style
        list
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
      }
      information {
        _key
        _type
        content {
          style
          list
          _type
          _key
          children {
            text
            marks
            _type
            _key
          }
        }
        title
      }
      store {
        id
        previewImageUrl
        isDeleted
        priceRange {
          minVariantPrice
          maxVariantPrice
          _key
        }
        slug {
          current
        }
        productType
        title
        tags
        status
        options {
          name
          values
        }
        variants {
          id
          store {
            isDeleted
            price
            previewImageUrl
            compareAtPrice
            title
            status
          }
        }
      }
      images {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          url
          mobile: gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        }
      }
      recommended {
        images {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            url
          }
        }
        cardImages {
          hoverImage {
            asset {
              url
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          image {
            asset {
              url
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
        store {
          id
          isDeleted
          priceRange {
            minVariantPrice
            maxVariantPrice
            _key
          }
          slug {
            current
          }
          productType
          title
          tags
          status
          options {
            name
            values
          }
          variants {
            id
            store {
              isDeleted
              price
              previewImageUrl
              compareAtPrice
              title
              status
            }
          }
        }
      }
    }
    settings: allSanitySettings {
      nodes {
        madeIn
        cardColour {
          hex
        }
      }
    }
    product: shopifyProduct(id: { eq: $id }) {
      id
      title
      description
      productType

      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        # altText
        src
        id
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        mobile: gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
      }
      variants {
        price
        compareAtPrice
        availableForSale
        inventoryQuantity

        storefrontId
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
        title
        id
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
  }
`
