import React from "react"
import RichText from "../text/RichText"
import { informat } from "./information.module.scss"
const InfoContent = ({ activeInfoIndex, infoContent }) => {
  const [activePanel, setPanel] = React.useState(true)
  React.useEffect(() => {
    setPanel(false)
    var timer = setTimeout(() => setPanel(true), 200)
    return () => {
      clearTimeout(timer)
    }
  }, [activeInfoIndex])
  return (
    <article className="md:col-span-2">
      <div
        className={`transition-all duration-700 ${informat} ${
          activePanel ? "max-h-96 border-gray-200" : ""
        }`}
      >
        <div
          className={`transition-opacity ${
            activePanel
              ? "py-20px lg:py-2 opacity-100 delay-500"
              : "opacity-0 delay-0 pointer-events-none"
          }`}
        >
          {infoContent &&
            infoContent.map(child => {
              child.markDefs = child.markDefs ? child.markDefs : []
              return <RichText key={child._key} blocks={child} />
            })}
        </div>
      </div>
    </article>
  )
}

export default InfoContent
