import React from "react"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "../section/carousel.scss"

import { GatsbyImage } from "gatsby-plugin-image"
const Carousel = ({ images }) => {
  const hasAssetProperty =
    images.length > 0 && images[0]?.hasOwnProperty("asset")

  return (
    <div className="relative flex w-full bg-white ">
      <Swiper
        modules={[Navigation]}
        slidesPerView={"auto"}
        className="w-full"
        navigation
      >
        {images &&
          images.map((image, index) => {
            return (
              <SwiperSlide
                key={index}
                className={`aspect-w-4 product aspect-h-5 w-full flex justify-center  ${
                  images.length === 1 ? "single" : ""
                }`}
              >
                <div>
                  <GatsbyImage
                    image={
                      hasAssetProperty ? image?.asset?.mobile : image?.mobile
                    }
                    className="object-center w-full h-full "
                    alt=""
                  />
                </div>
              </SwiperSlide>
            )
          })}
      </Swiper>
    </div>
  )
}

export default Carousel
